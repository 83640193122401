












import { Component, Vue, Prop } from "vue-property-decorator";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/chart/pie";
import { DashColors } from "@/views/Dashboards/Dashcolors";
import { DashTipoDatoPorcen } from "@/shared/dtos/DashboardsDtos/DashTipoDatoPorcen";
import { UtilsString } from "@/utils/utils-string";

@Component({
  components: {
    ECharts: () => import("vue-echarts/components/ECharts.vue")
  }
})
export default class PieChart extends Vue {
  @Prop({ default: "" }) titulo!: string;
  @Prop({ default: [] }) source!: DashTipoDatoPorcen[];
  @Prop({ default: DashColors.Ordencolors2 }) paleta!: string[];
  @Prop({ default: false }) labelshow!: boolean;

  public created() {}
  public get legend() {
    let lellenda: string[] = [];
    let total:number=0;
    for (let l = 0; l < this.source.length; l++) {
      total+= this.source[l].total
    }

    try {
      for (let l = 0; l < this.source.length; l++) {
        lellenda.push(UtilsString.FristLetterMayu(this.source[l].tipo));
      }
    } catch (error) {}
    return lellenda;
  }

  public get transformdata() {
    let dat = [];
    try {
      for (let i = 0; i < this.source.length; i++) {
        const element = this.source[i];
        dat.push({
          value: element.total,
          name: UtilsString.FristLetterMayu(element.tipo)
        });
      }
    } catch (error) {}

    return dat;
  }

  public get datapie() {
    return {
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)"
      },
      legend: {
        orient: "vertical",
        left: "left",
        data: this.legend
      },
      series: [
        {
          name: this.titulo,
          type: "pie",
          radius: "55%",
          center: ["50%", "60%"],
          color: this.paleta,
          label: {
            show: this.labelshow
          },
          data: this.transformdata,
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            }
          }
        }
      ]
    };
  }
}
